import React, { useState } from "react"
import * as Style from "./BannerSection.module.scss"
// import image from "../../../../assets/images/haskovo2.jpg"
import CustomButton from "../../../UI/custom-button/CustomButton"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { ProgressSection } from "../progress-section/ProgressSection"
// import TopVotes from "../../../top-votes/TopVotes"
// import HomepageUseful from "../homepage-useful/HomepageUseful"

const BannerSection = ({ message }) => {
  const [showPoster, setShowPoster] = useState(false)
  const showPosterHandler = () => {
    setShowPoster(!showPoster)
  }

  const navigateToInitiatives = path => {
    navigate(path)
  }

  return (
    <>
      {showPoster && (
        <div onClick={showPosterHandler} className={Style.posterModal}>
          <div className={Style.modalPosterImgWrapper}>
            <StaticImage
              src="../../../../assets/images/poster-new.png"
              alt="main-poster"
              placeholder="blurred"
              layout="constrained"
            />
          </div>
        </div>
      )}
      <section
        // style={{
        //   background: `url(${image}) no-repeat center/cover`,
        //   // opacity: "0.7",
        // }}
        className={`${Style.bannerSection} ${"main-container"}`}
      >
        <div className={Style.bannerInfo}>
          <div onClick={showPosterHandler} className={Style.infoBox}>
            <StaticImage
              src="../../../../assets/images/poster-new.png"
              alt="main-poster"
              placeholder="blurred"
              layout="constrained"
            />
          </div>

          <div className={Style.ctaWrapper}>
            {/* <CustomButton
              buttonHandler={navigateToInitiatives.bind(this, "/initiatives")}
              style={Style.btnOne}
              global={true}
            >
              Гласувай за инициатива
            </CustomButton> */}
            {/* <TopVotes /> */}
            <div className={Style.warrning}>
              <h4 className={Style.heading}>Важно съобщение!</h4>
              <p className={Style.message}>{message}</p>
            </div>
            <CustomButton
              buttonHandler={navigateToInitiatives.bind(this, "/idea")}
              style={Style.btnOne}
              global={true}
            >
              Изпрати идея !
            </CustomButton>

            {/* <CustomButton
              buttonHandler={navigateToInitiatives.bind(this, "/idea-list")}
              style={Style.btnTwo}
              global={true}
            >
              Виж други идеи
            </CustomButton> */}
          </div>
        </div>
      </section>
      <section
        className={`${Style.progressSectionContainer} ${"main-container"}`}
      >
        {/* <div className={Style.videoWrapper}>
          <iframe
            className={Style.video}
            src="https://www.youtube.com/embed/5Uws1cEv_yA?si=-k8iH-ubGNdGQ370"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div> */}
        {/* <HomepageUseful /> */}
        <ProgressSection />
        <div className={Style.infoBoxTwo}>
            Четвъртото издание на Програмата за граждански бюджети се реализира в рамките на проект “Граждански бюджети”. Той се осъществява благодарение на най-голямата социално отговорна инициатива на Лидл България „Ти и Lidl за нашето утре“, в партньорство с Фондация „Работилница за граждански инициативи“ и Български дарителски форум.
        </div>
      </section>
    </>
  )
}

export default BannerSection
